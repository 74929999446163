import React, {useState, useRef, useEffect} from "react"
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useMutation } from "react-query";
import ProgressBar from "../progress-bar/review_proposal_progress_bar";
import {
    CONFIDENTIALITY_AGREEMENT_STEP,
} from "../progress-bar/constants";
import { agreeConfidentiality } from "../../api/sage";


export const AdvisorConfidentialityAgreement = ({ formSubmitCb, expert, proposal, token }) => {
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false,
        error: '',
      });

    const formRef = useRef(null);

    const agreeConfidentialityMutation = useMutation(() => {
        return agreeConfidentiality(token);
    });

    const onSubmit = (data) => {
        agreeConfidentialityMutation.mutate();
    }

    useEffect(() => {
        if (agreeConfidentialityMutation.isSuccess) {
            formSubmitCb();
        }
    }, [agreeConfidentialityMutation.isSuccess]);

    return (
        <>
            <div className="c-proposal-review" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-proposal-review__form">
                            <ProgressBar currentStep={CONFIDENTIALITY_AGREEMENT_STEP} />
                            <div className="f-section">
                                <h3>Confidentiality Agreement</h3>
                                <p>
                                    The RDIA SART initiative 2024 application documents that you agree to review and to which you are about to be granted privileged access shall be considered strictly Confidential Information and shall not be discussed with any person other than SART initiative staff members or their nominated contacts at Outsmart Insight. Your involvement in the review of these documents shall be kept confidential and shall not be disclosed to anyone.
                                </p>
                                <p>&nbsp;</p>

                                <p>
                                    All information provided by SART initiative is in strict confidence, including the identities of SART initiative applicant team members and their associated relationships. The reviewer shall use such information only for the purpose of carrying out the review and for no other purpose. The information must not be used by the reviewer for personal benefit or made available for the personal benefit of any other individual or organization. As a reviewer you must not copy, quote, or otherwise use or disclose to any other party, including any person within your own organization or any third party, any material you receive from the SART initiative.
                                </p>
                                <p>&nbsp;</p>

                                <p>
                                    Your review shall be anonymous. While the reviews are shared with the proposal applicants, the identities of the reviewers are not. Any information that would enable the identification of the reviewer identity shall be excluded from the review.
                                </p>
                                <p>&nbsp;</p>

                                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    {state.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{state.error}</p>
                                          </div>
                                      </div>
                                    )}

                                    <div className="row">
                                        <div className="f-field f-field--full">
                                            <div className={classNames("f-field__elem checkbox",{"f-field__elem--has-error": errors.agree_terms}, "tcs")}>
                                                <input
                                                    className="f-control"
                                                    type="checkbox"
                                                    {...register("agree_terms", {required: "This field is required"})}
                                                    id={"agree_terms"}
                                                />
                                                <label htmlFor={"agree_terms"}>
                                                    I have read RDIA SART initiative 2024 Confidentiality Rules. I will not divulge or use any Confidential Information provided to me by RDIA.
                                                </label>
                                                {errors.agree_terms && <span className="f-error">{errors.agree_terms.message}</span>}
                                            </div>
                                        </div>

                                        <div className="f-field f-field--full">
                                            <button
                                                type="submit"
                                                className="c-btn c-btn--large c-btn--primary"
                                                disabled={agreeConfidentialityMutation.isLoading}
                                            >
                                                NEXT
                                            </button>
                                            {agreeConfidentialityMutation.isLoading && <p>Loading...</p>}
                                            {agreeConfidentialityMutation.isError && <span className="f-error">An error has occurred. Please try again.</span>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvisorConfidentialityAgreement;